import React, { useState, useEffect } from 'react';
import theme from "theme";
import { Theme, Link, Image, Section, Text, Box, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
export default (() => {

	const roundToMillions = (num) => (num / 1000000).toFixed(1) + " millions";

	// Function to round to thousands (K)
	const roundToK = (num) => (num / 1000).toFixed(2) + "k";

	const [linesOfData, setLinesOfData] = useState(0);
	const [emailsSent, setEmailsSent] = useState(0);
	const [checks, setChecks] = useState(0);
	console.log("Loading Data from API")
	// Function to fetch site data
	const fetchSiteData = async () => {
	  try {
		const response = await fetch('https://api.dataleaks.us/site-data');
		  if (!response.ok) {
			  console.error(`HTTP error! status: ${response.status}`);
			  return;
			}
		const data = await response.json();
		setLinesOfData(data.LinesOfData);
		setEmailsSent(data.EmailSent);
		setChecks(data.Checks);
	  } catch (error) {
		  
		console.error("Error fetching data:", error);
	  }
	};
  
	// Fetch data when the component mounts
	useEffect(() => {
	  fetchSiteData();
	}, []); // Empty dependency array means this useEffect runs once when the component mounts

	
	const handleButtonClick = () => {
		//console.log("123123");
		document.getElementById("apiResponse").innerText = "Please wait...";
		const apiUrl = "https://api.dataleaks.us/check-info";
	
		// Assume 'name' and 'email' are the IDs of your form inputs
		const name = document.getElementById("name").value;
		const email = document.getElementById("email").value;
	
		// Make the GET request
		fetch(`${apiUrl}?name=${name}&email=${email}`)
		  .then(response => response.text())
		  .then(data => {
			//console.log("Data received from the API", data);
			document.getElementById("apiResponse").innerHTML = data;
		  })
		  .catch(error => console.error("Error:", error));
	  };

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"team"} />
		<Helmet>
			<title>
				Password Check - Dataleaks.us
			</title>
			<meta name={"description"} content={"Password check, You can use our free tool to check if your information have been found in our leaked information database"} />
			<meta property={"og:title"} content={"Password Check - Dataleaks.us"} />
			<meta property={"og:description"} content={"Password check, You can use our free tool to check if your information have been found in our leaked information database"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://i.imgur.com/eGfhJiA.png"} type={"image/x-icon"} />
		</Helmet>
		<Section padding="16px 0 16px 0" quarkly-title="Header" align-items="center" justify-content="center">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Link href="/" position="relative" transition="opacity 200ms ease" quarkly-title="Link">
				<Image
					src="https://uploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00:59:29.119Z"
					width="125px"
					z-index="3"
					srcSet="https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Link>
			<Components.BurgerMenu md-align-items="center" md-justify-content="flex-end" md-display="flex">
				<Override
					slot="menu-open"
					md-bottom={0}
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					md-top={0}
				/>
				<Override slot="icon-open" md-right="18px" md-position="fixed" md-top="18px" />
				<Override
					slot="menu"
					lg-transition="transform 400ms ease 0s"
					md-top={0}
					align-items="center"
					md-height="100%"
					padding="0px 0 0px 0"
					justify-content="center"
					lg-transform="translateY(0px) translateX(0px)"
					md-position="fixed"
					display="flex"
					md-left={0}
					md-width="100%"
				>
					<Override
						slot="item"
						padding="8px 20px 8px 20px"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
						text-transform="uppercase"
						text-align="center"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						color="--dark"
						letter-spacing="0.5px"
						md-transition="opacity .15s ease 0s"
						font="--base"
						text-transform="initial"
						md-font="16px/24px sans-serif"
						transition="opacity .15s ease 0s"
						text-decoration-line="initial"
						opacity=".5"
						md-color="--dark"
						md-opacity=".5"
						hover-opacity="1"
						md-hover-opacity="1"
						md-active-opacity="1"
					/>
					<Override
						slot="link-active"
						md-opacity="1"
						md-cursor="default"
						opacity="1"
						color="--primary"
						cursor="default"
					/>
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
				<Override
					slot="icon"
					category="md"
					icon={MdMenu}
					size="36px"
					md-right="0px"
					md-position="relative"
				/>
			</Components.BurgerMenu>
			<Link
				href="/contact"
				md-display="none"
				white-space="nowrap"
				color="--light"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				font="--base"
				letter-spacing="0.5px"
				z-index="5"
				background="--color-primary"
				padding="8px 18px 8px 18px"
				border-radius="0px"
				hover-transform="translateY(-4px)"
				justify-self="end"
				text-decoration-line="initial"
			>
				Contact Us
			</Link>
		</Section>
		<Section quarkly-title="Hero" padding="25px 0 75px 0" lg-padding="25px 0 25px 0" justify-content="center">
			<Override
				slot="SectionContent"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
		</Section>
		<Section background="--color-light" color="--dark" padding="64px 0 64px 0">
			<Box
				margin="-16px -16px -16px -16px"
				display="flex"
				flex-wrap="wrap"
				align-content="center"
				justify-content="center"
			>
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%" text-align="left">
					<Box text-align="left">
						<Text
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
							margin="0"
						>
							password check:
						</Text>
						<Text font="--headline2" max-width="500px" margin="10px 0 0 0">
							Enter info below to check your info in our database.
						</Text>
					</Box>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" lg-width="100%" position="relative">
				<Box>
					<Box
					gap="16px"
					display="grid"
					flex-direction="row"
					flex-wrap="wrap"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					>
					<Box sm-width="100%" display="flex" flex-direction="column">
						<Text font="--base" margin="0 0 4px 0">
						Name
						</Text>
						<Input id="name" width="100%" name="name" type="text" />
					</Box>
					<Box sm-width="100%" display="flex" flex-direction="column">
						<Text font="--base" margin="0 0 4px 0">
						Email
						</Text>
						<Input id="email" width="100%" type="email" name="email" />
					</Box>
					<Box display="flex" flex-direction="column" align-items="flex-start" grid-column="1 / span 2">
						<Button id="checkBtn" onClick={handleButtonClick}>
						Check now!
						</Button>
	  					<br></br>
						<br></br>
						<Text id="apiResponse" font="--base" margin="0 0 4px 0">
						
						</Text>
					
					</Box>

					</Box>
				</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="120px 0 140px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-4">
			<Text margin="0px 0px 0px 0px" font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
				Our mission
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				md-flex-direction="column"
				sm-margin="30px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					margin="0px 10% 0px 0px"
					padding="4px 0px 0px 0px"
					md-width="100%"
					md-margin="0px 10% 50px 0px"
					sm-padding="0 0px 0px 0px"
					sm-margin="0px 10% 35px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						At DataLeaks.us, our main goal is to safeguard your online privacy and protect your personal data. In today's digital world, the threat of data breaches is real and growing. Every day, countless individuals unknowingly have their sensitive information exposed, from emails to bank details. We're here to combat that. Our team is dedicated to constantly monitoring the digital realm, identifying potential threats, and acting promptly to ensure your information stays out of the wrong hands. But it's not just about alerting you to data breaches; we're committed to education.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						We want to empower everyone with the knowledge and tools they need to stay safe online. From understanding the importance of strong, unique passwords to recognizing phishing attempts, our mission is to be your trusted guide in the vast online world. So whether you're a seasoned internet user or just getting started, you can trust DataLeaks.us to have your back every step of the way. Your safety is our priority, and we'll work tirelessly to keep it that way.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						{roundToMillions(linesOfData)}
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Lines of data collected.
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						{roundToK(emailsSent)}
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Emails sent out from our system
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						{roundToK(checks)}
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Password check performed
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 40px 0" quarkly-title="Footer-12">
			<Box
				display="grid"
				lg-width="100%"
				flex-direction="row"
				lg-flex-direction="row"
				justify-content="space-between"
				width="100%"
				padding="0 0px 0 0px"
				lg-padding="0 0px 50px 0px"
				margin="0px 0px 50px 0px"
				md-flex-wrap="wrap"
				md-padding="0 0px 0 0px"
				md-margin="0px 0px 40px 0px"
				grid-template-columns="repeat(3, 1fr)"
				lg-align-items="start"
				grid-gap="36px 34px"
				md-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					align-items="flex-start"
					lg-align-items="flex-start"
					justify-content="flex-start"
					display="grid"
					lg-flex-direction="column"
					flex-direction="column"
					flex-wrap="wrap"
					align-content="start"
					grid-gap="10px 0"
					lg-justify-content="start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans" md-margin="0px 0px 10px 0px">
						Menu
					</Text>
					<Link
						border-color="--color-primary"
						display="flex"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						margin="0px 0 0px 0"
						lg-border-width="0px 0px 0px 2px"
						href="/index"
						text-decoration-line="initial"
						color="--darkL1"
						hover-color="#6d32ec"
					>
						Home
					</Link>
					<Link
						margin="0px 0 0px 0"
						display="flex"
						href="/about"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						text-decoration-line="initial"
						color="--darkL1"
						hover-color="#6d32ec"
					>
						About
					</Link>
					<Link
						margin="0px 0 0px 0"
						hover-color="#6d32ec"
						href="/team"
						text-decoration-line="initial"
						color="--darkL1"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						display="flex"
					>
						Password-check
					</Link>
					<Link
						margin="0px 0 0px 0"
						hover-color="#6d32ec"
						href="https://article.dataleaks.us/"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						text-decoration-line="initial"
						color="--darkL1"
						display="flex"
					>
						Articles
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-width="100%"
					sm-padding="0px 0px 0px 0"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans">
						Contact
					</Text>
					<Text margin="0px 0px 20px 0px" font="--base" color="#5a5d64">
						support@dataleaks.us
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-width="100%"
					sm-padding="0px 0px 0px 0"
					md-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans">
						Dataleaks.us
					</Text>
					<Text margin="0px 0px 40px 0px" font="--base" color="#5a5d64">
						dataleaks.us is a non-profit organization that watches data breach events around the world, Helping people know and protect their properties.
					</Text>
				</Box>
			</Box>
			<Box
				display="block"
				padding="0 0px 0px 0px"
				border-color="--color-lightD2"
				md-padding="0 0px 0px 0px"
				md-flex-direction="column"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="#5a5d64"
					md-margin="0px 0px 25px 0px"
					sm-text-align="center"
					text-align="center"
					letter-spacing="1px"
				>
					© 2021-2023 Dataleaks.us  . All rights reserved.
				</Text>
			</Box>
		</Section>


	</Theme>;
});